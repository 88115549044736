<script setup>
import { userAuthState } from "@/@core/auth"
import { VNodeRenderer } from "@layouts/components/VNodeRenderer"
import { themeConfig } from "@themeConfig"
import { computed } from "vue"
import { useRouter } from 'vue-router'
import logout from '@/services/logout'  

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: false,
  },
})

const router = useRouter()
const auth = userAuthState()

// const modules = [
//   {
//     title: "Feedback",
//     desc: "Feedback Description",
//     icon: "tabler-clipboard-text",
//     to: '/app/feedback/dashboard'
//   },
//   {
//     title: "Incident",
//     desc: "Incident Description",
//     icon: "tabler-traffic-cone",
//     to: '/app/incident/dashboard'
//   },
// ];

const computedModules = computed(() => {
  let data = []
  auth?.user?.modules?.forEach(element => {
    data = [
      ...data,
      MODULES.find(x => x.name === element.name),
    ]
  })

  // data = [
  //   ...data,
  //   {
  //     name: "Global Settings",
  //     desc: "Manage global settings",
  //     icon: "tabler-settings",
  //     to: '/app/global/users',
  //   },
  // ]
  
  return data
})

const menuItems = [
  {
    title: "Global Settings",
    icon: "tabler-settings",
    action: () => router.push('/app/global/users'),
  },

  // {
  //   title: "Notifications",
  //   icon: "tabler-notification",
  //   action: () => {},
  // },
  // {
  //   title: "User Profile",
  //   icon: "tabler-user",
  //   action: () => {},
  // },
  {
    title: "Log Out",
    icon: "tabler-logout",
    action: logout,
  },
]
</script>

<template>
  <div class="bg-image">
    <VContainer>
      <!-- header -->
      <div class="d-flex align-center">
        <VNodeRenderer
          :nodes="themeConfig.app.client.logo"
          class="mb-6 me-auto"
        />

        <!-- desktop menu -->
        <div class="d-sm-flex d-none gap-4">
          <VBtn
            v-for="item in menuItems"
            :key="item.title"
            variant="text"
            color=""
            @click="item.action"
          >
            <VIcon
              size="22"
              :icon="item.icon"
              class="me-2"
            />
            {{ item.title }}
          </VBtn>
        </div>
        <!-- desktop menu close -->

        <!-- mobile menu -->
        <div class="d-sm-none">
          <VMenu>
            <template #activator="{ props }">
              <VBtn
                color="default"
                variant="text"
                v-bind="props"
                icon="tabler-menu-2"
                size="x-large"
              />
            </template>

            <VList>
              <VListItem
                v-for="item in menuItems"
                :key="item.title"
                @click="item.action"
              >
                <VListItemTitle class="text-h6">
                  <VIcon
                    size="22"
                    :icon="item.icon"
                    class="me-2"
                  />
                  {{ item.title }}
                </VListItemTitle>
              </VListItem>
            </VList>
          </VMenu>
        </div>
        <!-- mobile menu close -->
      </div>
      <!-- header close -->

      <VRow class="mt-0 mt-md-8">
        <!-- left -->
        <VCol
          cols="12"
          md="6"
        >
          <div class="d-flex flex-column gap-4 mt-0 mt-md-16">
            <template
              v-for="module in computedModules"
              :key="module.name"
            >
              <RouterLink :to="module.to">
                <VCard
                  class="cursor-pointer hover-border"
                  flat
                >
                  <VCardText class="d-flex align-center gap-3">
                    <div class="me-auto">
                      <h5 class="text-h5">
                        {{ module.name }}
                      </h5>
                      <p class="text-body-1 mb-0">
                        {{ module.desc }}
                      </p>
                    </div>

                    <VIcon
                      size="40"
                      :icon="module.icon"
                      class="text-high-emphasis"
                    />
                  </VCardText>
                </VCard>
              </RouterLink>
            </template>
          </div>
        </VCol>
        <!-- left close -->

        <!-- right -->
        <VCol
          cols="12"
          md="6"
        >
          <div class="d-flex justify-center justify-md-end">
            <div class="d-flex flex-column justify-center justify-md-end align-center gap-6">
              <VImg
                :width="400"
                src="/assets/panel-page/collect-feedback.svg"
              />
              <VImg
                :width="350"
                src="/logo/light.svg"
              />
            </div>
          </div>
        </VCol>
      <!-- right close -->
      </VRow>
    </VContainer>
  </div>
</template>

<style scoped>
.hover-border {
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}

.hover-border:hover {
  border-color: green;
}

.bg-image {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: url( '/assets/panel-page/background.svg') left center no-repeat;
  background-position: right;
  background-size: cover;
}

@media (max-width: 600px) {
  .bg-image {
    background: none !important;
    background-color: #d8e6db !important;
  }
}
</style>
