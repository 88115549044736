<script setup>
import { useFeedbackEntry } from '@core/stores/feedback-entry'

import { cookieRef } from '@/@layouts/stores/config'
import CustomerFetchDialog from '@/views/customer/dialogs/CustomerFetchDialog.vue'
import AssortedFeedbackQuestion from '@/views/feedback/feedback-entry/tabs/AssortedFeedbackQuestion.vue'
import CustomerInfo from '@/views/feedback/feedback-entry/tabs/CustomerInfo.vue'
import FeedbackType from '@/views/feedback/feedback-entry/tabs/FeedbackType.vue'
import StandardFeedbackQuestion from '@/views/feedback/feedback-entry/tabs/StandardFeedbackQuestion.vue'

definePage({
  meta: {
    name: 'feedback-entry',
    subject: 'feedback.feedback',
    action: 'create',
  },  
})

const { t } = useI18n()

const route = useRoute('feedback-collection')
const feedbackId = ref(0)

const iconsSteps = [
  {
    title: 'Feedback Details',
  },
  {
    title: t('Customer')+' Info',
  },
  {
    title: 'Feedback Form',
  },
  {
    title: 'Review & Submit',
  },
]

const isCustomerDialogOpen = ref(false)

const refFeedbackTypeForm = ref()

const feedbackTypeFormData = ref({
  customerType: null,
  sourceType: null,
  formType: null,
})

useFeedbackEntry().fetchFormTypes()
useFeedbackEntry().fetchSourceTypes(route.params.type)

const refCustomerInfoForm = ref()

const customerInfoFormData = ref({
  name: '',
  phone: '',
  email: '',
  uhid: '',
  episode_no: '',
  location: '',
  customer_id: null,
  unit_id: null,
  episode_id: null,
  feedback_datetime: null,
})

const feedbackFormData = ref({})
const currentStep = ref(0)
const isCurrentStepValid = ref(true)


const validateFeedbackTypeForm = () => {
  refFeedbackTypeForm.value?.validate().then(valid => {
    if (valid.valid) {
      currentStep.value++
      isCurrentStepValid.value = true
      useFeedbackEntry().updateCurrentFeedback({
        customer_type: feedbackTypeFormData.value?.customerType,
        source_type: feedbackTypeFormData.value?.sourceType,
        form_type: feedbackTypeFormData.value?.formType,
        feedback_type: type.value,
        feedback: [],

      })
      nextTick(() => {
        refFeedbackTypeForm.value?.resetValidation()
        refFeedbackForm.value?.reset()
      })
    } else {
      isCurrentStepValid.value = false
    }
  })
}

const validateCustomerInfoForm = () => {
  refCustomerInfoForm.value?.resetValidation()

  if(customerInfoFormData.value?.customer_id !== null){
    updateCustomerInfoFormData()
  }
  refCustomerInfoForm.value?.validate().then(valid => {
    if (valid.valid) {
      currentStep.value++
      isCurrentStepValid.value = true
      nextTick(() => {
        refCustomerInfoForm.value?.resetValidation()
        updateCustomerInfoFormData()
      })
    } else {
      isCurrentStepValid.value = false
    }
  })
}

const refFeedbackForm = ref()

const updateCustomerInfoFormData =()=> {
 
  useFeedbackEntry().updateCurrentFeedback({
    customer: customerInfoFormData.value,
    unit_id: cookieRef('selectedUnit'),
    feedback: [],
  })
  refFeedbackForm.value?.reset()

}


const validateFeedbackForm = () => {
  refFeedbackForm.value?.validate().then(valid => {
    if (valid.valid) {
      currentStep.value++
      isCurrentStepValid.value = true
      useFeedbackEntry().updateCurrentFeedback({
        feedback: feedbackFormData.value?.filter(item => {
          if (item.response !== null && item.response !== '' && item.response !== undefined) {
            return item
          }
          
        }),
      })
      nextTick(() => {
        refFeedbackForm.value?.resetValidation()
      })
    } else {

      isCurrentStepValid.value = false
    }
  })
}

const resetFeedbackForm = () => {
  refFeedbackForm.value?.reset()
  useFeedbackEntry().updateCurrentFeedback({
    feedback: [],
  })
}

watch(feedbackFormData, () => {
  if (Array.isArray(feedbackFormData.value) && feedbackFormData.value.length > 0){
    useFeedbackEntry().updateCurrentFeedback({
      feedback: feedbackFormData.value?.filter(item => {
        if (item.response !== null && item.response !== '' && item.response !== undefined) {
          return item
        }
          
      }),
    })
  }
}, {
  immediate: true,
  deep: true,
})

const updateCurrentStep = value => {
  currentStep.value = value
}

const type =computed(() => route.params.type)

const breadcrumbs = computed(() => {
  return [
    { title: 'Feedback Entry' },
    { title: type.value==='assorted' ? 'Assorted' : 'Standard'+' Feedback', to: type.value==='assorted' ? '/app/feedback-entry/assorted' : '/app/feedback-entry' },
    { title: 'Collect' },
  ]
})

const questionFeedbackComponent= computed(() => {
  return type.value==='assorted' ? AssortedFeedbackQuestion : StandardFeedbackQuestion
})

const submitLocation = ref(false)



const onSuccess = data => {
  //  reset customerInfoFormData and feedbackTypeFormData
  customerInfoFormData.value = {
    name: '',
    phone: '',
    email: '',
    uhid: '',
    episode_no: '',
    location: '',
    customer_id: null,
    episode_id: null,
  }

  feedbackTypeFormData.value = {
    customerType: null,
    sourceType: null,
    formType: null,
  }

  feedbackFormData.value = {}
    
  useFeedbackEntry().updateCurrentFeedback({
    customer: {
      customer_id: null,
      name: '',
      uhid: '',
      episode_no: '',
      email: '',
      phone: '',
      feedback_datetime: null,
      location: null,
    },
    unit_id: null,
    location_name: null,
    feedback_type: '',
    source_type: '',
    form_type: '',
    customer_type: '',
    feedback: [],
    attachment: null,
  })
 
  submitLocation.value = false
  feedbackId.value++
  currentStep.value = 0
}

const onSubmit = data => {
  submitLocation.value = true
  useFeedbackEntry().submitCurrentFeedback(false, onSuccess)

}


const isCustomerApiConfigured = ref(false)


// check customer selection api available
await useApi(createUrl('/configurations/customer-api-status', {
  query: {
    unit_id: cookieRef('selectedUnit'),
  },
}), {
  afterFetch: response => {
    isCustomerApiConfigured.value = response?.data?.status
  },
})

onBeforeMount(() => {
  useFeedbackEntry().resetCurrentFeedback()
})

watch(feedbackTypeFormData, () => {
  if(feedbackTypeFormData.value?.customerType === 'PATIENT'){
    if (isCustomerApiConfigured.value &&(!isCustomerDialogOpen.value && customerInfoFormData.value?.customer_id === null)) {
      isCustomerDialogOpen.value = true
    }
  }else{
    customerInfoFormData.value = {
      name: '',
      phone: '',
      email: '',
      uhid: '',
      episode_no: '',
      location: '',
      customer_id: null,
      episode_id: null,
    }
  }
}, {
  deep: true,
  immediate: true,
})

// watch(customerInfoFormData, () => {
//   refCustomerInfoForm.value?.resetValidation()
 
// })


const getResponseValue=response=> {
  if (Array.isArray(response)) {
    return response.map(item => item?.label ?? item?.value ?? item).join(', ')
  }
  
  return response?.label ?? response?.value ?? response
}

const updateCustomerData = data => {
  customerInfoFormData.value = {
    ...customerInfoFormData.value,
    ...data,
  }
}

// if no customerInfoFormData selected than clear customerType
</script>

<template>
  <!-- 👉 Stepper -->
  <VBreadcrumbs :items="breadcrumbs" />
  <div class="mb-6">
    <AppStepper
      v-model:current-step="currentStep"
      :items="iconsSteps"
      :is-active-step-valid="isCurrentStepValid"
    />
  </div>

  <VCard>
    <!-- 👉 stepper content -->
    <VForm>
      <VWindow
        v-model="currentStep"
        class="disable-tab-transition"
      >
        <VWindowItem class="bg-var-theme-white">
          <VForm
            ref="refFeedbackTypeForm"
            @submit.prevent="validateFeedbackTypeForm"
          >
            <FeedbackType
              :data="feedbackTypeFormData"
              :current-step="currentStep"
              @update:current-step="updateCurrentStep"
              @update:feedback-type-form-data="feedbackTypeFormData = $event"
            />  
          </VForm>
        </VWindowItem>
        <VWindowItem>
          <VCardItem>
            <VForm
              ref="refCustomerInfoForm"
              @submit.prevent="validateCustomerInfoForm"
            >
              <CustomerInfo
                :data="customerInfoFormData"
                :current-step="currentStep"
                @update:customer-info-form-data="customerInfoFormData = $event"
                @update:current-step="updateCurrentStep"
              />  
            </VForm>
          </VCardItem>
        </VWindowItem>
        <VWindowItem>
          <VCardItem>
            <VForm
              ref="refFeedbackForm"
              @submit.prevent="validateFeedbackForm"
            >
              <Component
                :is="questionFeedbackComponent"
                :key="feedbackId"
                :current-step="currentStep"
                @update:current-step="updateCurrentStep"
                @update:feedback-type-form-data="feedbackFormData = $event"
                @update:reset-feedback-form="resetFeedbackForm"
              />
            </VForm>
          </VCardItem>
        </VWindowItem>
       
        <VWindowItem>
          <VCardItem>
            <VForm
              ref="refReviewAndSubmitForm"
              @submit.prevent="onSubmit"
            >
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VCardTitle
                      level="4"
                      class="mb-4"
                    >
                      Review & Submit
                    </VCardTitle>
                  </VCol>
                  <VCol cols="12">
                    <VCardItem>
                      <div class="text-base">
                        <h6 class="text-base font-weight-medium mb-2">
                          Form Type
                        </h6>

                        <p class="mb-1">
                          Form Type: {{ useFeedbackEntry().getFilledFeedback?.form_type?.title }}
                        </p>
                        <p class="mb-1">
                          {{ $t('Customer') +' Type: ' }}{{ useFeedbackEntry().getFilledFeedback?.source_type?.title }}
                        </p>

                        <VDivider class="my-4" />

                        <h6 class="text-base font-weight-medium mb-2">
                          {{ $t('Customer') }} Info
                        </h6>
                        <p class="mb-1">
                          Name : {{ useFeedbackEntry().getFilledFeedback?.customer?.name }}
                        </p>
                        <p
                          v-if="useFeedbackEntry().getFilledFeedback?.customer?.uhid"
                          class="mb-1"
                        >
                          UHID : {{ useFeedbackEntry().getFilledFeedback?.customer?.uhid }}
                        </p>
                        <p
                          v-if="useFeedbackEntry().getFilledFeedback?.customer?.episode_no"
                          class="mb-1"
                        >
                          Episode No : {{ useFeedbackEntry().getFilledFeedback?.customer?.episode_no }}
                        </p>
                        <p class="mb-1 text-body-1">
                          Mobile No : {{ useFeedbackEntry().getFilledFeedback?.customer?.phone }}
                        </p>
                        <p class="mb-1 text-body-1">
                          Location : {{ useFeedbackEntry().getFilledFeedback?.customer?.location?.name }}
                        </p>
                        <VDivider class="my-4" />

                        <h6 class="text-base font-weight-medium mb-2">
                          Feedback Response
                        </h6>
                        <div
                          v-for="(item, index) in useFeedbackEntry().getFilledFeedback?.feedback"
                          :key="index"
                        >
                          <p class="mb-1 text-body-2">
                            {{ item.question_label }}
                          </p>
                          <p class="mb-1 text-body-1 text-high-emphasis">
                            {{ getResponseValue(item.response) }}
                          </p>
                        </div>
                      </div>
                    </VCardItem>
                  </VCol>
                </VRow>
                <VCol cols="12">
                  <div class="d-flex flex-wrap gap-4 justify-space-between mt-8">
                    <VBtn
                      color="secondary"
                      variant="tonal"
                      :disabled="currentStep === 0"
                      @click="currentStep--"
                    >
                      <VIcon
                        icon="tabler-arrow-left"
                        start
                        class="flip-in-rtl"
                      />
                      Previous
                    </VBtn>
        
                    <VBtn
                      color="success"
                      type="submit"
                      :loading="submitLocation"
                    >
                      submit
                    </VBtn>
                  </div>
                </VCol>
              </VCardText>
            </VForm>
          </VCardItem>
        </VWindowItem>
      </VWindow>
    </VForm>
  </VCard>
  <CustomerFetchDialog
    v-model:isDialogOpen="isCustomerDialogOpen"
    @update:customer-dialog-data="updateCustomerData"
    @update:clear-customer-selection="feedbackTypeFormData.customerType = ''"
  />
</template>
