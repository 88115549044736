<script setup>
import { incidentTitle } from "@/enums/incident/incident.status"
import { RcaStatus, rcaStatusColor, rcaStatusTitle } from '@/enums/incident/rca.status'
import { ReviewStatus, reviewStatusColor, reviewStatusTitle } from '@/enums/incident/review.status'
import CapaService from "@/services/incident/capa.service"
import RcaService from "@/services/incident/rca.service"
import { computed, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"

import IncidentDetailsView from "@/views/incident/incident-panel/views/Details.vue"
import InvolvedPartiesView from "@/views/incident/incident-panel/views/InvolvedParties.vue"
import IncidentQuestionsView from "@/views/incident/incident-panel/views/Questions.vue"

import Table from "@/@core/components/Table.vue"
import { createUrl } from "@/@core/composable/createUrl"
import { useApi } from "@/composables/useApi"
import { incidentLikelihoodTitle } from '@/enums/incident/incident.likelihood'
import { incidentSeverityTitle } from '@/enums/incident/incident.severity'
import FishboneDrawer from "@/views/incident/rca/investigation/FishboneDrawer.vue"
import FiveWhyDrawer from "@/views/incident/rca/investigation/FiveWhyDrawer.vue"
import GeneralDrawer from "@/views/incident/rca/investigation/GeneralDrawer.vue"
import RiskAssessmentDrawer from "@/views/incident/rca/RiskAssessmentDrawer.vue"

import Review from "@/views/incident/rca/Review.vue"
import { userAuthState } from '@core/auth'


definePage({
  meta: {
    name: "incident-rca-view",
    subject: "incident.incident_rca",
    action: "read",
  },
})

const route = useRoute()
const router = useRouter()

const rca = ref()

const { execute: refetchRCAs } = await useApi(
  createUrl(`/incidents/incident-rcas/${route.params.id}`),
  {
    afterFetch: ({ data }) => {
      rca.value = data
    },
    onFetchError: error => {
      router.push({
        name: "invalid-url",
        params: { code: 404 },
      })
    },
  },
)


const auth = userAuthState()

const { data: incident } = await useApi(
  createUrl(`/incidents/incidents/${rca.value.incident.id}`),
)

const isGeneralDrawerVisible = ref(false)

const generalRcaStrategy = computed(() => {
  return rca.value.incident_rca_strategies.find(item => item.rca_strategy === 'GENERAL')
})

const generalRcaCollapsed = computed(() => !!!generalRcaStrategy.value?.id)

const isFiveWhyDrawerVisible = ref(false)

const fiveWhyRcaStrategy = computed(() => {
  return rca.value.incident_rca_strategies.find(item => item.rca_strategy === 'FIVE_WHYS')
})

const isFiveWhyCollapsed = computed(() => !!!fiveWhyRcaStrategy.value?.id)

const isFishboneDrawerVisible = ref(false)

const fishboneRcaStrategy = computed(() => {
  return rca.value.incident_rca_strategies.find(item => item.rca_strategy === 'FISHBONE')
})


const authUserIsVerifier = computed(() => {
  return rca.value?.verifiers?.findIndex(verifier => verifier.id === auth?.user?.id) > -1
})


const authUserIsDelegate = computed(() => {
  let isDelegate = false
  isDelegate = rca.value?.user_delegates?.findIndex(delegate => delegate.delegate.id === auth?.user?.id) > -1
  if (isDelegate === false) {
    isDelegate = rca.value?.group_delegates?.findIndex(delegate => delegate.delegate.users.findIndex(user => user.id === auth?.user?.id) > -1) > -1
  }
  
  return isDelegate
})


const isFishboneCollapsed = computed(() => !!!fishboneRcaStrategy.value?.id)

const updateRcaStrategies = data => {
  rca.value.rca_status = data.rca_status
  rca.value.incident_rca_strategies = data.incident_rca_strategies
  rca.value.incident.incident_status = data.incident?.incident_status ?? rca.value.incident.incident_status
  rca.value.verifiers = data.verifiers
  rca.value.activities = data.activities
}

const {
  data: capas,
  execute: fetchCapas,
} = await useApi(createUrl('incidents/capas', {
  query: {
    search: {
      incident_rca_id: rca.value.id,
    },
  },
}))

const isCapaDrawerVisible = ref(false)
const capaForm = ref({})
const refCapaForm = ref()

const isCapaCollapsed = computed(() => {
  return  capas.value.length === 0 ? true : false
})

const {
  data: contributoryFactorData,
  execute: fetchContributoryFactors,
} = await useApi(
  createUrl(`incidents/incident-rcas/${rca.value.id}/contributory-factors`),
  {
    // afterFetch: ({ data }) => {
    //   data.forEach(item => {
    //     if (!capas.value.find(capa => capa.contributory_factor_id === item.id)) {
    //       contributoryFactors.value.push(item)
    //     }
    //   })
    // },
  },
)

const contributoryFactors = computed(() => {
  const data = []

  contributoryFactorData.value.forEach(item => {
    if (!capas.value.find(capa => capa.contributory_factor_id === item.id)) {
      data.push(item)
    }
  })

  return data
})

const onCapaCreate = () => {
  isCapaDrawerVisible.value = true
}

const onCapaEdit = data => {
  capaForm.value = {
    id: data.id,
    contributory_factor: data.contributory_factor,
    corrective_action: data.corrective_action,
    preventive_action: data.preventive_action,
  }
  isCapaDrawerVisible.value = true
}

const isCapaLoading = ref(false)

const submitCapaForm = async () => {

  const valid = await refCapaForm.value.validate().then(({ valid }) => {
    return valid
  })

  if (valid === false) return

  isCapaLoading.value = true

  const formData = {
    incident_rca_id: rca.value.id,
    contributory_factor_id: capaForm.value.contributory_factor.id,
    ...capaForm.value,
  }

  if (capaForm.value.id) {
    await CapaService.update(
      capaForm.value.id,
      formData,
      data => {
        //Update Data
        updateRcaCapa(data.incident_rca)     
        fetchCapas()

        isCapaDrawerVisible.value = false
      },
      () => {},
    )
  } else {
    await CapaService.store(
      formData,
      data => {
        //Update Data
        updateRcaCapa(data.incident_rca)
        fetchCapas()

        isCapaDrawerVisible.value = false
      },
      () => {},
    )
  }

  isCapaLoading.value = false

}

const onCapaDelete = async id => {
  await CapaService.destroy(id, data => {
    //Update Data
    updateRcaCapa(data)
    fetchCapas()
  })
}

watch(() => isCapaDrawerVisible.value, value => {
  if (value === false) capaForm.value = {}
  if (value === true) fetchContributoryFactors()
})

const updateRcaCapa = data => {
  rca.value.rca_status = data.rca_status
  rca.value.capa_status = data.capa_status
  rca.value.capa_rate_comment = data.capa_rate_comment
  rca.value.incident.incident_status = data.incident?.incident_status ?? rca.value.incident.incident_status
  rca.value.verifiers = data.verifiers
  rca.value.activities = data.activities

}

const isRiskAssessmentDrawerVisible = ref(false)

const isRiskAssessmentCollapsed = computed(() => {
  return rca.value.risk_priority_no ? false : true
})

const updateRcaRiskAssessment = data => {
  // Update rating and status
  rca.value.rca_status = data.rca_status
  rca.value.risk_assessment_status = data.risk_assessment_status
  rca.value.risk_assessment_rating = data.risk_assessment_rating
  rca.value.risk_assessment_rate_comment = data.risk_assessment_rate_comment
  rca.value.verifiers = data.verifiers
  rca.value.activities = data.activities


  // Update data
  rca.value.risk_priority_no = data.risk_priority_no
  rca.value.likelihood = data.likelihood
  rca.value.severity = data.severity
  rca.value.risk_assessment_response = data.risk_assessment_response

  // Update Incident
  rca.value.incident.incident_status = data.incident?.incident_status ?? rca.value.incident.incident_status
}

const reviewRca = () => {
  RcaService.verificationRequest(
    rca.value.id,
    data => {
      rca.value.rca_status = data.rca_status
      rca.value.incident.incident_status = data.incident?.incident_status ?? rca.value.incident.incident_status
      rca.value.verifiers = data.verifiers
      rca.value.activities = data.activities

    },
    () => {},
  )
}

const verifyForm = ref({})
const refVerifyForm = ref()
const isVerifyFormLoading = ref(false)
const isVerifyDialog = ref(false)

const submitVerify = async () => {

  const valid = await refVerifyForm.value.validate().then(({ valid }) => {
    return valid
  })

  if (valid === false) return

  RcaService.verificationVerify(
    rca.value.id,
    verifyForm.value,
    data => {
      isVerifyDialog.value = false
      rca.value.rca_status = data.rca_status
      rca.value.incident.incident_status = data.incident?.incident_status ?? rca.value.incident.incident_status
      rca.value.verifiers = data.verifiers
      rca.value.activities = data.activities

    },
    () => {},
  )

}

watch(isVerifyDialog, value => {
  if (value === false) verifyForm.value = {} 
})

const computedReviews = computed(() => {
  const data = []

  rca.value.incident_rca_strategies.forEach(item => {
    if (item.rating) {
      let title
      if (item.rca_strategy == 'GENERAL') {
        title = 'General'
      } else if (item.rca_strategy == 'FISHBONE') {
        title = 'Fishbone'
      } else if (item.rca_strategy == 'FIVE_WHYS') {
        title = 'Five Why'
      }
      data.push({
        title: title,
        rating: item.rating,
        review_comment: item.review_comment,
      })
    }
  })
  if (rca.value.capa_rating) {
    data.push({
      title: "CAPA",
      rating: rca.value.capa_rating,
      review_comment: rca.value.capa_rate_comment,
    })
  }
  if (rca.value.risk_assessment_rating) {
    data.push({
      title: "Risk Assessment",
      rating: rca.value.risk_assessment_rating,
      review_comment: rca.value.risk_assessment_rate_comment,
    })
  }
  
  return data
})

const capaColumns =computed(() => {
  let columns = [
    { key: 'factor', title: 'Factor' },
    { key: 'corrective_action', title: 'Corrective Action' },
    { key: 'preventive_action', title: 'Preventive Action' },
  ]

  if (authUserIsDelegate.value) {
    columns.push({ key: 'action', title: 'Action' })
  }

  return columns
})
</script>

<template>
  <VContainer fluid>
    <VRow>
      <VCol
        cols="12"
        md="8"
      >
        <!-- Incident Details -->
        <AppCardActions
          action-collapsed
          :collapsed="true"
        >
          <template #title>
            {{ incident.code }}
          </template>

          <template #before-actions>
            <VChip :color="resolveIncidentStatusColor(rca.incident.incident_status)">
              {{ incidentTitle(rca.incident.incident_status) }}
            </VChip>
          </template>

          <VCardText>
            <VRow justify="space-around">
              <VCol
                cols="12"
                order="1"
              >
                <IncidentDetailsView :data="incident" />
                <InvolvedPartiesView :data="incident" />
                <IncidentQuestionsView :data="incident" />
              </VCol>
            </VRow>    
          </VCardText>
        </AppCardActions>
        <!-- incident details over -->
      </VCol>
    </VRow>
    <VRow>
      <VCol
        cols="12"
        md="8"
      >
        <!-- Investigator Panel & RCA Details -->
        <VCard>
          <template #title>
            <div class="text-h5">
              Investigator Panel   
            </div>
            <div class="text-body-1">
              {{ formatDate(rca.created_at) }}
            </div>
          </template>
          <!-- <template #subtitle></template> -->
          <template #append>
            <VChip
              variant="elevated"
              size="large"
              color="primary"
            >
              {{ rca.title }}
            </VChip>
          </template>
          <VCardText>
            <div class="d-flex flex-column gap-6">
              <VAlert
                variant="tonal"
                border="start"
                border-color="primary"
              >
                <div>
                  Notes
                </div>
                <span>
                  {{ rca.delegation_notes }}
                </span>
              </VAlert>
            
              <div>
                <div class="text-h5">
                  Delegated Users & Groups
                </div>
                <VContainer>
                  <VRow
                    v-if="rca?.user_delegates?.length > 0"
                    class="d-flex flex-wrap ga-4"
                  >
                    <!-- Individual User Cards -->
                    <VCol 
                      v-for="user in rca.user_delegates" 
                      :key="user.id"
                      cols="12"
                      sm="4"
                      md="3"
                    >
                      <VCard
                        elevation="1"
                        class="rounded-lg"
                      >
                        <VListItem class="py-2">
                          <template #prepend>
                            <VAvatar
                              size="40"
                              color="primary"
                              variant="tonal"
                            >
                              <VImg
                                v-if="user?.delegate?.avatar"
                                :src="user?.delegate?.avatar"
                              />
                              <div v-else>
                                {{ avatarText(user?.delegate?.name) }}
                              </div>
                            </VAvatar>
                          </template>
              
                          <VListItemTitle class="text-subtitle-1 ">
                            {{ user?.delegate?.name }}
                          </VListItemTitle>
                          <VListItemSubtitle class="text-body-2 text-grey-darken-1">
                            {{ user?.delegate?.employee_code }}
                          </VListItemSubtitle>
                        </VListItem>
                      </VCard>
                    </VCol>
                  </VRow>
                  <VRow
                    v-if="rca?.group_delegates?.length > 0"
                    class="groups-list"
                  >
                    <VCol
                      v-for="(group, index) in rca.group_delegates"
                      :key="index"
                      cols="12"
                    >
                      <VCard border>
                        <VCardTitle class="text-h6">
                          {{ group.delegate.name }}
                        </VCardTitle>
                        <VCardText>
                          <div class="d-flex flex-wrap gap-2">
                            <div
                              v-for="(user, userIndex) in group.delegate.users"
                              :key="userIndex"
                            >
                              <VCard
                                elevation="1"
                                class="rounded-lg"
                              >
                                <VListItem class="py-2">
                                  <template #prepend>
                                    <VAvatar
                                      size="40"
                                      color="primary"
                                      variant="tonal"
                                    >
                                      <VImg
                                        v-if="user?.avatar"
                                        :src="user?.avatar"
                                      />
                                      <div v-else>
                                        {{ avatarText(user?.name) }}
                                      </div>
                                    </VAvatar>
                                  </template>
                    
                                  <VListItemTitle class="text-subtitle-1 ">
                                    {{ user?.name }}
                                  </VListItemTitle>
                                  <VListItemSubtitle class="text-body-2 text-grey-darken-1">
                                    {{ user?.employee_code }}
                                  </VListItemSubtitle>
                                </VListItem>
                              </VCard>
                            </div>
                          </div>
                        </VCardText>
                      </VCard>
                      <div />
                    </VCol>
                  </VRow>
                </VContainer>
              </div>
            </div>
          </VCardText>
  
          <VDivider />
        </VCard>
  
        <!-- General Investigation -->
        <AppCardActions
          class="mt-6"
          title="General Investigation"
          action-collapsed
          :collapsed="generalRcaCollapsed"
        >
          <template #title>
            <div>
              General Investigation
            </div>
            <div 
              v-if="generalRcaStrategy?.rating"
              class="d-flex align-center me-6 text-h6"
            >
              <VRating
                v-model="generalRcaStrategy.rating"
                color="warning"
                :size="25"
                half-increments
                :disabled="true"
              />
            </div>
          </template>
          <template #before-actions="{ data }">
            <!-- last updated by -->
            <div>
              <VChip
                v-if="generalRcaStrategy?.status"
                class="me-2"
                :color="reviewStatusColor(generalRcaStrategy?.status)"
              >
                {{ reviewStatusTitle(generalRcaStrategy?.status) }}
              </VChip>
            </div>
            
            <VBtn 
              v-if="$can('perform_rca', 'incident.incident_rca') &&
                (
                  rca.rca_status === RcaStatus.PENDING ||
                  rca.rca_status === RcaStatus.DRAFT ||
                  rca.rca_status === RcaStatus.RE_OPENED
                )
                && authUserIsDelegate
              "
              size="small"
              color="primary"
              @click="() => {
                // if (data.isContentCollapsed === true) data.triggerCollapse()
                isGeneralDrawerVisible = !isGeneralDrawerVisible 

              }"
            >
              {{ generalRcaStrategy ? 'Revise' : 'Investigate' }}
            </VBtn>
          </template>

          <!-- reopen comment -->
          <VCardText
            v-if="generalRcaStrategy?.status === ReviewStatus.RE_OPENED && generalRcaStrategy?.review_comment"
            class="me-3"
          >
            <VAlert
              color="error"
              icon="tabler-alert-circle"
              variant="tonal"
            >
              <span class="font-weight-bold">Reason for Re-Open: </span>{{ generalRcaStrategy?.review_comment }}
            </VAlert>
          </VCardText>
          <!-- reopen comment close -->
  
            
          <VCardText v-if="generalRcaStrategy">
            <div>
              <span class="text-subtitle-2 font-weight-bold">
                Brief Description:
              </span><br>
              <span class="text-body-4">
                {{ generalRcaStrategy.brief_of_investigation }}
              </span>
            </div>
  
            <!-- Factors Table -->
            <div 
              v-for="(factor, key) in [
                { title: 'Broad Contributory Factors', key: 'broad_contributory_factors' },
                { title: 'Specific Contributory Factors', key: 'specific_contributory_factors' },
              ]"
              :key="key" 
              class="mt-4"
            >
              <span class="text-subtitle-2 font-weight-bold">
                {{ factor.title }}
              </span>
              <Table 
                class="border mt-1"
                :columns="[
                  { key: 'factor', title: 'Factor' },
                  { key: 'child_factors', title: 'Child Factors' },
                  { key: 'remarks', title: 'Remark' },
                ]"
                :items="generalRcaStrategy[factor.key]"
              >
                <template #factor="{ item }">
                  {{ item.name }}
                </template>
                <template #child_factors="{ item }">
                  <div class="d-flex gap-2">
                    <VChip
                      v-for="(child, k) in item.child_factors"
                      :key="k"
                    >
                      {{ child.child_factor }}
                    </VChip>
                  </div>
                </template>
              </Table>
            </div>

            <!-- last updated by -->
            <div class="d-flex align-center justify-end mt-3">
              <div class=" mt-4 d-flex align-center gap-2">
                <span class="text-subtitle-2 font-weight-bold">
                  Last Updated By: 
                </span>
                <div>
                  <VAvatar
                    size="30"
                    color="primary"
                    variant="tonal"
                  >
                    <VImg
                      v-if="generalRcaStrategy?.done_by?.avatar"
                      :src="generalRcaStrategy?.done_by?.avatar"
                    />
                    <div v-else>
                      {{ avatarText(generalRcaStrategy?.done_by?.name) }}
                    </div>
  
                    <VTooltip
                      activator="parent"
                      location="end"
                    >
                      <div>
                        {{ generalRcaStrategy?.done_by?.name }} ( {{ generalRcaStrategy?.done_by?.employee_code }} )
                        <br>
                        {{ formatDate(generalRcaStrategy?.updated_at) }}
                      </div>
                    </VTooltip>
                  </VAvatar>
                </div>
              </div>
            </div>
            <!-- last updated by close -->
          </VCardText>
  

          <div
            v-if="rca.rca_status === RcaStatus.VERIFIED &&
              generalRcaStrategy?.status === ReviewStatus.REVIEW_PENDING &&
              generalRcaStrategy
            "
          >
            <VDivider class="mt-2" />
  
            <VCardText>
              <Review
                type="RCA"
                strategy="GENERAL"
                :rca="rca"
                @update:rca="updateRcaStrategies"
              />
            </VCardText>
          </div>
        </AppCardActions>
  
        <!-- FiveWhy Investigation -->
        <AppCardActions
          class="mt-6"
          title="Five Why Investigation Strategy"
          action-collapsed
          :collapsed="isFiveWhyCollapsed"
          :disabled="generalRcaStrategy ? false : true"
        >
          <template #title>
            <div>
              Five Why Investigation Strategy
            </div>
            <div 
              v-if="fiveWhyRcaStrategy?.rating"
              class="d-flex align-center me-6 text-h6"
            >
              <VRating
                v-model="fiveWhyRcaStrategy.rating"
                color="warning"
                :size="25"
                half-increments
                :disabled="true"
              />
            </div>
          </template>
          <template #before-actions>
            <VChip
              v-if="fiveWhyRcaStrategy?.status"
              class="me-2"
              :color="reviewStatusColor(fiveWhyRcaStrategy?.status)"
            >
              {{ reviewStatusTitle(fiveWhyRcaStrategy?.status) }}
            </VChip>
  
            <VBtn 
              v-if="$can('perform_rca', 'incident.incident_rca') &&
                (
                  rca.rca_status === RcaStatus.PENDING ||
                  rca.rca_status === RcaStatus.DRAFT ||
                  rca.rca_status === RcaStatus.RE_OPENED
                )
                && authUserIsDelegate
              "
              size="small"
              color="primary"
              @click="isFiveWhyDrawerVisible = !isFiveWhyDrawerVisible"
            > 
              {{ fiveWhyRcaStrategy ? 'Revise' : 'Investigate' }}
            </VBtn>
          </template>
  
          <VCardText v-if="fiveWhyRcaStrategy">
            <div>
              <span class="text-subtitle-2 font-weight-bold">
                Brief Description:
              </span><br>
              <span class="text-body-4">
                {{ fiveWhyRcaStrategy.brief_of_investigation }}
              </span>
            </div>
  
            <div class="mt-4 d-flex flex-column gap-3">
              <div
                v-for="(item, index) in fiveWhyRcaStrategy.five_whys"
                :key="index"
              >
                <span class="text-subtitle-2 font-weight-bold">
                  {{ `${item.level}. ${item.question}` }}
                </span><br>
                <span class="text-body-4">
                  {{ `${typeof item.response != "object" ? item.response : item.response.label}` }}
                </span>
              </div>
            </div>
          </VCardText>
  
          <VCardText v-if="fiveWhyRcaStrategy?.status === ReviewStatus.RE_OPENED">
            <VAlert
              color="error"
              icon="tabler-alert-circle"
              variant="tonal"
            >
              <span class="font-weight-bold">Reason for Re-Open: </span>{{ fiveWhyRcaStrategy?.review_comment }}
            </VAlert>
          </VCardText>
  
          <div v-if="rca.rca_status === RcaStatus.VERIFIED && fiveWhyRcaStrategy?.status === ReviewStatus.REVIEW_PENDING && fiveWhyRcaStrategy">
            <VDivider class="mt-2" />
  
            <VCardText>
              <Review
                type="RCA"
                strategy="FIVE_WHYS"
                :rca="rca"
                @update:rca="updateRcaStrategies"
              />
            </VCardText>
          </div>
        </AppCardActions>
  
        <!-- Fishbone Investigation -->
        <AppCardActions
          class="mt-6"
          title="Fishbone Investigation Strategy"
          action-collapsed
          :collapsed="isFishboneCollapsed"
          :disabled="generalRcaStrategy ? false : true"
        >
          <template #title>
            <div>
              Fishbone Investigation Strategy
            </div>
            <div 
              v-if="fishboneRcaStrategy?.rating"
              class="d-flex align-center me-6 text-h6"
            >
              <VRating
                v-model="fishboneRcaStrategy.rating"
                color="warning"
                :size="25"
                half-increments
                :disabled="true"
              />
            </div>
          </template>
          <template #before-actions>
            <VChip
              v-if="fishboneRcaStrategy?.status"
              class="me-2"
              :color="reviewStatusColor(fishboneRcaStrategy?.status)"
            >
              {{ reviewStatusTitle(fishboneRcaStrategy?.status) }}
            </VChip>

            <VBtn 
              v-if="$can('perform_rca', 'incident.incident_rca') &&
                (
                  rca.rca_status === RcaStatus.PENDING ||
                  rca.rca_status === RcaStatus.DRAFT ||
                  rca.rca_status === RcaStatus.RE_OPENED
                )
                && authUserIsDelegate
              "
              size="small"
              color="primary"
              @click="isFishboneDrawerVisible = !isFishboneDrawerVisible"
            >
              {{ fishboneRcaStrategy ? 'Revise' : 'Investigate' }}
            </VBtn>
          </template>
  
          <VCardText v-if="fishboneRcaStrategy">
            <div>
              <span class="text-subtitle-2 font-weight-bold">
                Brief Description:
              </span><br>
              <span class="text-body-4">
                {{ fishboneRcaStrategy.brief_of_investigation }}
              </span>
            </div>
  
            <div
              v-for="(item, index) in fishboneRcaStrategy.fishbone"
              :key="index"
              class="mt-2"
            >
              <span class="text-subtitle-2 font-weight-bold">
                {{ item.fishbone_category }}
              </span>
              <ol class="ml-6">
                <li
                  v-for="(element, key) in item.reasons"
                  :key="key"
                >
                  {{ element }}
                </li>
              </ol>
            </div>
          </VCardText>
  
          <VCardText v-if="fishboneRcaStrategy?.status === ReviewStatus.RE_OPENED">
            <VAlert
              color="error"
              icon="tabler-alert-circle"
              variant="tonal"
            >
              <span class="font-weight-bold">Reason for Re-Open: </span>{{ fishboneRcaStrategy?.review_comment }}
            </VAlert>
          </VCardText>
  
          <div v-if="rca.rca_status === RcaStatus.VERIFIED && fishboneRcaStrategy?.status === ReviewStatus.REVIEW_PENDING && fishboneRcaStrategy">
            <VDivider class="mt-2" />
  
            <VCardText>
              <Review
                type="RCA"
                strategy="FISHBONE"
                :rca="rca"
                @update:rca="updateRcaStrategies"
              />
            </VCardText>
          </div>
        </AppCardActions>
  
        <!-- CAPA -->
        <AppCardActions
          class="mt-6"
          title="CAPA"
          action-collapsed
          :collapsed="isCapaCollapsed"
          :disabled="generalRcaStrategy ? false : true"
        >
          <template #title>
            <div>
              Corrective & Preventive Action
            </div>
            <div 
              v-if="rca?.capa_rating"
              class="d-flex align-center me-6"
            >
              <VRating
                v-model="rca.capa_rating"
                color="warning"
                :size="25"
                half-increments
                :disabled="true"
              />
            </div>
          </template>
          <template #before-actions>
            <VChip
              v-if="rca?.capa_status"
              class="me-2"
              :color="reviewStatusColor(rca?.capa_status)"
            >
              {{ reviewStatusTitle(rca?.capa_status) }}
            </VChip>
  
           
            <VBtn 
              v-if="$can('capa', 'incident.incident_rca') &&
                (
                  rca.rca_status === RcaStatus.PENDING ||
                  rca.rca_status === RcaStatus.DRAFT ||
                  rca.rca_status === RcaStatus.RE_OPENED
                ) && authUserIsDelegate
              "
              size="small"
              color="primary"
              @click="onCapaCreate"
            > 
              Add CAPA
            </VBtn>
          </template>

          <VCardText v-if="rca?.capa_status === ReviewStatus.RE_OPENED && rca?.capa_rate_comment">
            <VAlert
              color="error"
              icon="tabler-alert-circle"
              variant="tonal"
            >
              <span class="font-weight-bold">Reason for Re-Open: </span>{{ rca?.capa_rate_comment }}
            </VAlert>
          </VCardText>
  
          <VCardText>
            <Table 
              class="border mt-1"
              :columns="capaColumns"
              :items="capas"
            >
              <template #factor="{ item }">
                {{ item.contributory_factor.name }}
              </template>
              <template #corrective_action="{ item }">
                {{ item.corrective_action ?? 'N/A' }}
              </template>
              <template #preventive_action="{ item }">
                {{ item.preventive_action ?? 'N/A' }}
              </template>
              <template #action="{ item }">
                <div 
                  v-if="$can('capa', 'incident.incident_rca') &&
                    (
                      rca.rca_status === RcaStatus.PENDING ||
                      rca.rca_status === RcaStatus.DRAFT ||
                      rca.rca_status === RcaStatus.RE_OPENED
                    ) && authUserIsDelegate
                  "
                  class="d-flex gap-x-3"
                >
                  <span 
                    class="cursor-pointer"
                    @click="onCapaEdit(item)"
                  >
                    <VIcon 
                      size="22"
                      icon="tabler-edit"
                    />
                  </span>
                  <span 
                    class="cursor-pointer"
                    @click="onCapaDelete(item.id)"
                  >
                    <VIcon 
                      size="22"
                      icon="tabler-trash"
                    />
                  </span>
                </div>
              </template>
            </Table>
          </VCardText>
  
          <div v-if="rca.rca_status === RcaStatus.VERIFIED && rca?.capa_status === ReviewStatus.REVIEW_PENDING && capas.length > 0">
            <VDivider class="mt-2" />
  
            <VCardText>
              <Review
                type="CAPA"
                :rca="rca"
                @update:rca="updateRcaCapa"
              />
            </VCardText>
          </div>
        </AppCardActions>
  
        <!-- risk assessment -->
        <AppCardActions
          class="mt-6"
          title="Risk Priority & Assessment"
          action-collapsed
          :collapsed="isRiskAssessmentCollapsed"
          :disabled="capas.length > 0 ? false : true"
        >
          <template #title>
            <div>
              Risk Priority & Assessment
            </div>
            <div 
              v-if="rca?.risk_assessment_rating"
              class="d-flex align-center me-6 text-h6"
            >
              <VRating
                v-model="rca.risk_assessment_rating"
                color="warning"
                :size="25"
                half-increments
                :disabled="true"
              />
            </div>
          </template>
          <template #before-actions>
            <VChip
              v-if="rca?.risk_assessment_status"
              class="me-2"
              :color="reviewStatusColor(rca?.risk_assessment_status)"
            >
              {{ reviewStatusTitle(rca?.risk_assessment_status) }}
            </VChip>
  
            <VBtn 
              v-if="$can('risk_assessment', 'incident.incident_rca') &&
                (
                  rca.rca_status === RcaStatus.PENDING ||
                  rca.rca_status === RcaStatus.DRAFT ||
                  rca.rca_status === RcaStatus.RE_OPENED
                ) && authUserIsDelegate
              "
              size="small"
              color="primary"
              @click="isRiskAssessmentDrawerVisible = !isRiskAssessmentDrawerVisible"
            > 
              {{ rca.risk_priority_no ? 'Update' : 'Add Risk Assessment' }}
            </VBtn>
          </template>

          <VCardText
            v-if="rca?.risk_assessment_status === ReviewStatus.RE_OPENED"
            class="mt-2"
          >
            <VAlert
              color="error"
              icon="tabler-alert-circle"
              variant="tonal"
            >
              <span class="font-weight-bold">Reason for Re-Open: </span>{{ rca?.risk_assessment_rate_comment }}
            </VAlert>
          </VCardText>

          <VCardText v-if="rca.risk_priority_no">
            <div class="d-flex gap-x-1">
              <span class="text-subtitle-2 font-weight-bold">
                Risk Priority Score:
              </span><br>
              <span class="text-body-4">
                {{ rca.risk_priority_no }}
              </span>
            </div>
            <div class="d-flex gap-x-1">
              <span class="text-subtitle-2 font-weight-bold">
                Severity:
              </span><br>
              <span class="text-body-4">
                {{ incidentSeverityTitle(rca.severity) }}
              </span>
            </div>
            <div class="d-flex gap-x-1">
              <span class="text-subtitle-2 font-weight-bold">
                Likelihood:
              </span><br>
              <span class="text-body-4">
                {{ incidentLikelihoodTitle(rca.likelihood) }}
              </span>
            </div>
            <div class="mt-2 d-flex flex-column gap-3">
              <div 
                v-for="(item, index) in rca.risk_assessment_response"
                :key="index"
              >
                <span><b>{{ `${index + 1}. ${item.incident_questions.label}` }}</b></span> <br>
                <div class="ml-0">
                  {{ `ans: ${typeof item.response != "object" ? item.response : item.response.label}` }}
                </div>
              </div>
            </div>
          </VCardText>
  
  
          <div v-if="rca.rca_status === RcaStatus.VERIFIED && rca?.risk_assessment_status === ReviewStatus.REVIEW_PENDING && rca.risk_priority_no">
            <VDivider class="mt-2" />
  
            <VCardText>
              <Review
                type="RISK_ASSESSMENT"
                :rca="rca"
                @update:rca="updateRcaRiskAssessment"
              />
            </VCardText>
          </div>
        </AppCardActions>
      </VCol>
  
      <VCol
        cols="12"
        md="4"
      >
        <div class="pl-md-2 pl-sm-0">
          <!-- status chip -->
          <div class="text-h5 d-none d-sm-flex mb-5 justify-space-between">
            <VChip
              class="pa-2 "
              variant="elevated"
              :color="rcaStatusColor(rca.rca_status)"
              size="20"
            >
              <div class="px-15">
                {{ titleCase(rca.rca_status) }}
              </div>
            </VChip>

            <!-- watchers list -->
            <div
              v-if="rca?.watchers?.length > 0"
              class="v-avatar-group"
            >
              <template
                v-for="(user, index) in rca?.watchers"
                :key="user.id"
              >
                <VAvatar
                  v-if="rca?.watchers?.length > 4 && rca?.watchers?.length !== 4 && index < 3"
                  size="36"
                  :color="$vuetify.theme.current.dark ? '#4A5072' : '#f6f6f7'"
                >
                  <VTooltip
                    activator="parent"
                    location="end"
                  >
                    <div>
                      {{ user.name }}
                      <br>
                      {{ user.employee_code }}
                    </div>
                  </VTooltip>
                  <VImg 
                    v-if="user.avatar"
                    :src="user.avatar"
                  />
                  <span v-else>
                    {{ avatarText(user.name) }}
                  </span>
                </VAvatar>
  
                <VAvatar
                  v-if="rca?.watchers?.length < 4"
                  size="36"
                  :color="$vuetify.theme.current.dark ? '#f6f6f7' : '#4A5072'"
                >
                  <VTooltip
                    activator="parent"
                    location="end"
                  >
                    <div>
                      {{ user.name }}
                      <br>
                      {{ user.employee_code }}
                    </div>
                  </VTooltip>
                  <VImg 
                    v-if="user.avatar"
                    :src="user.avatar"
                  />
                  <span v-else>
                    {{ avatarText(user.name) }}
                  </span>
                </VAvatar>
              </template>
              <VAvatar
                v-if="rca?.watchers?.length > 4"
                :color="$vuetify.theme.current.dark ? '#f6f6f7' : '#4A5072'"
              >
                <span>
                  +{{ rca?.watchers?.length - 3 }}
                </span>
              </VAvatar>
            </div>
          </div>
          <!-- department verification -->
          <VCard 
            v-if="$can('verification', 'incident.incident_rca') &&
              rca.rca_status === RcaStatus.DRAFT &&
              capas.length > 0 && rca.risk_priority_no && generalRcaStrategy
              
            "
            class="mt-6"
          >
            <template #title>
              Send for Verification
            </template>
            <template #subtitle>
              <span style="white-space: normal !important;">
                Review the incident and send it for verification.
              </span>
            </template>
            <VCardText>
              <!-- send for approval -->
              <VBtn 
                color="primary"
                block
                @click="reviewRca"
              >
                Send for Verification
              </VBtn>
              <!-- send for approval btn close -->
            </VCardText>
          </VCard>
          <!-- department verification close -->

          <!-- verification -->
          <VCard 
            v-if="$can('verification', 'incident.incident_rca') && rca.rca_status === RcaStatus.COMPLETED
              && authUserIsVerifier
            "
            class="mt-6"
          >
            <template #title>
              Investigation Verification
            </template>
            <template #subtitle>
              <span style="white-space: normal !important;">
                You have the option to approve the investigation or re-open it for further investigation.
              </span>
            </template>
            <VCardText>
              <VRow>
                <VCol cols="6">
                  <VBtn 
                    class="flex-1-0"
                    color="primary"
                    block
                    @click="() => {
                      verifyForm.rca_status = RcaStatus.VERIFIED
    
                      isVerifyDialog = !isVerifyDialog
                    }"
                  >
                    Verify
                  </VBtn>
                </VCol>
                <VCol cols="6">
                  <VBtn 
                    class="flex-1-0"
                    color="error"
                    block
                    @click="() => {
                      verifyForm.rca_status = RcaStatus.RE_OPENED
                      isVerifyDialog = !isVerifyDialog
                    }"
                  >
                    Re-Open
                  </VBtn>
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
     

          <!-- verifiers -->
          <VCard class="mt-6">
            <template #title>
              <div class="d-flex align-center">
                <div class="me-4">
                  Verifiers
                </div>
                <VDivider 
                  :thickness="2" 
                  color="primary"
                />
              </div>
            </template>
            <VCardText>
              <VList class="card-list pt-4">
                <VListItem
                  v-for="(user, index) in rca.verifiers"
                  :key="index"
                >
                  <template #prepend>
                    <VBadge
                      v-if="user?.remark"
                      :color="user?.status === ReviewStatus.RE_OPENED ? 'error' : 'success'"
                      :icon="user?.status === ReviewStatus.RE_OPENED ? 'tabler-alert-circle' : 'tabler-check'"
                    >
                      <VTooltip
                        activator="parent"
                        location="start"
                      >
                        {{ user.remark }}
                      </VTooltip>
                      <VAvatar
                        size="34"
                        color="secondary"
                        variant="tonal"
                        class="me-1"
                      >
                        <VImg 
                          v-if="user.avatar"
                          :src="user.avatar"
                        />
                        <span v-else>
                          {{ avatarText(user.name) }}
                        </span>
                      </VAvatar>
                    </VBadge>
                    <VAvatar
                      v-else
                      size="34"
                      color="secondary"
                      variant="tonal"
                      class="me-1"
                    >
                      <VImg 
                        v-if="user.avatar"
                        :src="user.avatar"
                      />
                      <span v-else>
                        {{ avatarText(user.name) }}
                      </span>
                    </VAvatar>
                  </template>
                  <VListItemTitle class="font-weight-medium">
                    {{ user.name }}
                  </VListItemTitle>
                  <VListItemSubtitle>
                    {{ user.employee_code }}
                  </VListItemSubtitle>
                </VListItem>
              </VList>
            </VCardText>
          </VCard>


          <!-- Investigation Ratings -->
          <VCard class="mt-6">
            <template #title>
              <div class="d-flex align-center">
                <div class="me-4">
                  Investigation Ratings
                </div>
                <VDivider 
                  :thickness="2" 
                  color="primary"
                />
              </div>
            </template>
            <VCardText>
              <div class="d-flex flex-column gap-4">
                <div 
                  v-for="(review, index) in computedReviews"
                  :key="index"
                >
                  <div class="d-flex align-center">
                    <div class="me-auto text-subtitle-1 font-weight-medium">
                      {{ review.title }}
                    </div>
                    <VChip 
                      class="me-3"
                      size="small"
                      color="warning"
                    >
                      {{ formatRating(review.rating) }}
                    </VChip>
                    <div>
                      <VRating
                        v-model="review.rating"
                        color="warning"
                        :size="20"
                        half-increments
                        :disabled="true"
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    {{ review.review_comment }}
                  </div>
                </div>
              </div>
            </VCardText>
          </VCard>
          <!-- Investigation Ratings close -->

          <!-- Investigation Activity -->
          <VCard class="mt-6">
            <template #title>
              <div class="d-flex align-center">
                <div class="me-4">
                  Investigation Activity
                </div>
                <VDivider 
                  :thickness="2" 
                  color="primary"
                />
              </div>
            </template>
            <VCardText>
              <VTimeline
                side="end"
                align="start"
                line-inset="8"
                truncate-line="start"
                density="compact"
              >
                <!-- Activity Item -->
                <VTimelineItem
                  v-for="(activity, index) in rca.activities"
                  :key="index"
                  :dot-color="randomColor()"
                  size="x-small"
                >
                  <!-- 👉 Header -->
                  <div class="d-flex justify-space-between align-center gap-2 flex-wrap mb-2">
                    <span class="app-timeline-title">
                      {{ activity.description }}
                    </span>
                    <span class="app-timeline-meta">
                      {{ formatDate(activity.created_at) }}
                    </span>
                  </div>

                  <!-- 👉 Content -->
                  <!--
                    <div class="app-timeline-text mt-1">
                    Invoices have been paid to the company
                    </div> 
                  -->

                  <!--
                    <div class="d-inline-flex align-center timeline-chip mt-2">
                    <img
                    :src="pdf"
                    height="20"
                    class="me-2"
                    alt="img"
                    >
                    <span class="app-timeline-text font-weight-medium">
                    invoice.pdf
                    </span>
                    </div> 
                  -->

                  <div class="d-flex justify-space-between align-center flex-wrap">
                    <!-- 👉 Avatar & Personal Info -->
                    <div class="d-flex align-center mt-2">
                      <VAvatar
                        size="32"
                        class="me-2"
                        :color="$vuetify.theme.current.dark ? '#f6f6f7' : '#4A5072'"
                      >
                        <span>
                          {{ avatarText(activity.causer.name) }}
                        </span>
                      </VAvatar>
                      <div class="d-flex flex-column">
                        <p class="text-sm font-weight-medium text-medium-emphasis mb-0">
                          {{ activity.causer.name }}
                        </p>
                        <span class="text-sm">
                          {{ activity.causer.employee_code }}
                        </span>
                      </div>
                    </div>
                  </div>
                </VTimelineItem>
                <!-- !SECTION -->                
              </VTimeline>
            </VCardText>
          </VCard>
          <!-- Investigation Activity close -->
        </div>
  
        <!--
          <VCard class="mt-6">
          <VCardItem
          title="Rating"
          />
          <VCardText>
  
          </VCardText>
          </VCard> 
        -->
      </VCol>
    </VRow>
  </VContainer>

  <GeneralDrawer
    v-model:isDrawerOpen="isGeneralDrawerVisible"
    :rca="rca"
    :incident="incident"
    @update:rca="updateRcaStrategies"
  />

  <FiveWhyDrawer
    v-model:isDrawerOpen="isFiveWhyDrawerVisible"
    :rca="rca"
    :incident="incident"
    @update:rca="updateRcaStrategies"
  />

  <FishboneDrawer
    v-model:isDrawerOpen="isFishboneDrawerVisible"
    :rca="rca"
    :incident="incident"
    @update:rca="updateRcaStrategies"
  />

  <!-- CAPA Dialog -->
  <VDialog
    v-model="isCapaDrawerVisible"
    max-width="500"
  >
    <template #default="{ }">
      <VCard :title="capaForm.id ? 'Update CAPA' : 'Create CAPA'">
        <VCardText>
          <VForm ref="refCapaForm">
            <div class="d-flex flex-column gap-4">
              <AppSelect
                v-model="capaForm.contributory_factor"
                :items="contributoryFactors"
                :item-title="(item) => item.name"
                :item-value="(item) => item.id"
                label="Factor"
                placeholder="Select Factor"
                :return-object="true"
                :rules="[requiredValidator]"
                :required="true"
              />

              <AppTextarea
                v-model="capaForm.corrective_action"
                rows="3"
                label="Corrective Action"
                placeholder="Write Description"
                :rules="[requiredValidator]"
                :required="true"
              />

              <AppTextarea
                v-model="capaForm.preventive_action"
                rows="3"
                label="Preventive Action"
                placeholder="Write Description"
                :rules="[requiredValidator]"
                :required="true"
              />
            </div>

            <div class="d-flex justify-end gap-4 mt-6">
              <VBtn
                variant="tonal"
                color="error"
                style="block-size: 40px;"
                @click="isCapaDrawerVisible = !isCapaDrawerVisible"
              >
                Close
              </VBtn>
              <VBtn
                variant="tonal"
                style="block-size: 40px"
                :loading="isCapaLoading"
                @click="submitCapaForm"
              >
                {{ capaForm.id ? 'Update' : 'Add' }}
              </VBtn>
            </div>
          </VForm>
        </VCardText>
      </VCard>
    </template>
  </VDialog>

  <RiskAssessmentDrawer
    v-model:isDrawerOpen="isRiskAssessmentDrawerVisible"
    :rca="rca"
    @update:rca="updateRcaRiskAssessment"
  />

  <!-- Verify -->
  <VDialog
    v-model="isVerifyDialog"
    max-width="500"
  >
    <template #default="{ }">
      <VCard :title="rcaStatusTitle(verifyForm.rca_status)">
        <VCardText>
          <VForm ref="refVerifyForm">
            <div class="d-flex flex-column gap-4">
              <AppTextarea
                v-model="verifyForm.remark"
                rows="3"
                label="Remark"
                placeholder="Write A Remark"
                :rules="[requiredValidator]"
                :required="true"
              />
            </div>

            <div class="d-flex justify-end gap-4 mt-6">
              <VBtn
                variant="tonal"
                color="error"
                style="block-size: 40px;"
                @click="isVerifyDialog = !isVerifyDialog"
              >
                Close
              </VBtn>
              <VBtn
                variant="tonal"
                style="block-size: 40px"
                :loading="isCapaLoading"
                @click="submitVerify"
              >
                Submit
              </VBtn>
            </div>
          </VForm>
        </VCardText>
      </VCard>
    </template>
  </VDialog>
</template>
